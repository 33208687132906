import { type ReactElement } from 'react';

export default function LoadingSpinner({ color = 'border-indigo-500' }: { color?: string }): ReactElement {
  return (
    <div
      style={{ borderTopColor: 'transparent' }}
      className={`w-4 h-4 border-2 ${color} border-solid rounded-full animate-spin`}
    ></div>
  );
}
